import $ from 'jquery';

export default function toggleSubnav () {
	$('#navigation-jump').on('click', function toggleSubnav (e) {
		e.preventDefault();
		$('#subnav-clone').toggleClass('dom-shift-navigation--open');
		$(this).toggleClass('navigation-jump--open');
	});
}

