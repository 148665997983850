import $ from 'jquery';

export default function equalHeight (parent, child) {
    $(window).on('load', function () {
        $(parent).each(function eachParent (parentIndex, parentElement) {
            const children  = $(parentElement).find(child);
            const rows = {};

            children.each(function eachChild (childIndex, childElement) {
                $(childElement).height('auto');
                $(childElement).css('min-height', 'auto');
                const top = 'top: ' + $(childElement).offset().top;
                if (rows[top]) {
                    rows[top].push(childElement);
                }
                else {
                    rows[top] = [childElement];
                 }
            });

            $.each(rows, function(name, row) {
                if (row.length < 2) {
                    return;
                }
                const heights = row.map(function (el) {
                    return $(el).height();
                });
                const maxHeight = Math.max.apply(null, heights);
                $.each(row, function(i, el) {
                    $(el).css('min-height', `${maxHeight}px`);
                });
            });
        });
    });
}
