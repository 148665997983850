import $ from 'jquery';
import skipLinks from './utils/skipLinks';
import iframer from './utils/iframer';
import mNav from './utils/mNav';
import searchExpand from './utils/searchExpand';
import equalHeight from './utils/equalHeight';
import setupDomMoves from './utils/setupDomMoves';
import toggleSubnav from './utils/toggleSubnav';

function globals () {

    // iframe video in body content
    iframer();

    // Small Screen Navigation
    mNav(
        '#navigation-primary-toggle',
        'navigation-primary-toggle--open',
        '#navigation-primary__items',
        'navigation-primary__items--open'
    );

    // Navigation jump for mobile
    // Finds sidebar nav and pulls it into page dom?
    setupDomMoves();
    // Shows the nav.
    toggleSubnav();

    // equal height chart titles
    equalHeight('.panels-parent', '.panel');

}

$(function run () {
    console.log('ᕕ( ᐛ )ᕗ Running...');
    globals();
});
